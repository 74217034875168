import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInPage from "./pages/SignInPage /SignInPage";
import ReadPage from "./pages/ReadPage/ReadPage";
// import ReviewPage from './pages/ReviewPage/ReviewPage';
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import HomePage from "./pages/HomePage/HomePage";
import LessonPage from "./pages/LessonPage/LessonPage";
import PreventMobilePage from "./pages/PreventMobilePage/PreventMobilePage";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faTwitter, faFontAwesome, faEnvelope)

export default function App() {
  
  return (

      <Routes>
        
          <Route   path="/" element={<SignInPage />} />
          <Route   path="/home" element={<HomePage />} />
          {/* <Route   path="/review" element={<ReviewPage />} /> */}
          <Route   path="/lesson" element={<LessonPage />} />
          <Route   path="/read" element={<ReadPage />} />
          <Route   path="/not-supported-on-mobile" element={<PreventMobilePage />} />
          <Route   path="*" element={<ErrorPage />} />

           {/* <Route   path="/signup" element={<SignInPage />} /> */}

      </Routes>
  );
}
