import React from 'react';
import { Pane, Dialog, Text} from 'evergreen-ui';

import img08 from "../../images/illustrations/img08.svg"

function WelcomeDialog({shown, shownFunction}){

    return(
        <Pane>
        <Dialog 
        isShown={shown}
        title={"Welcome"}
        onCloseComplete={()=>{shownFunction(false)}}
        shouldCloseOnOverlayClick={true}
        preventBodyScrolling
        confirmLabel="Start"
        hasFooter= {false}
        hasCancel={true}
        hasClose={false}
        minHeightContent={400}
        onConfirm={()=>console.log("")}>

            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <img src={img08} alt=''/>
                        <Text fontSize={17} style={{marginTop:25}}>Welcome to Wennly !</Text>
                        <Text fontSize={17} style={{marginTop:25}}>Your first step towards character confidence.</Text>
            </div>
          
        </Dialog>
      </Pane>
    )
}

export default WelcomeDialog;