import "./Dashboard.css";

// ------------------------------------------------------------ //


// Dashboard
function Dashboard(){

    return(

        <div id="dashboard" className="">
            
            <div className="dash_island" id="item1">
                <p className="island_title">Recent Activity</p>
                <div className="island_data_container"></div>
            </div>

            <div className="dash_island" id="item2">
                <p className="island_title">Finished Character</p>
                <div className="island_data_container"></div>
            </div>

            <div className="dash_island" id="item3">
                <p className="island_title">Remaining Characters</p>
                <div className="island_data_container"></div>
            </div>

            <div className="dash_island" id="item4">
                <p className="island_title">Current Level</p>
                <div className="island_data_container"></div>
            </div>

            <div className="dash_island" id="item5">
                <p className="island_title">Other</p>
                <div className="island_data_container"></div>
            </div>

        </div>
        
    )
}



export default Dashboard;