import React from 'react';
import { Pane, Dialog, Button, Text} from 'evergreen-ui';
import { useNavigate } from "react-router-dom";

import img32 from "../../images/illustrations/img32.svg"

// Dialog to return home
function LessonFinishedDialog({shown, shownFunction}){

        // Page navigator object
        const navigate = useNavigate();

    return(
    <Pane>
        <Dialog
            title={"Lesson Completed"}
            isShown={shown}
            preventBodyScrolling
            onCloseComplete={()=>{shownFunction(false)}}
            shouldCloseOnOverlayClick={true}
            hasClose={true}
            hasCancel={false}
            confirmLabel="Continue"
            onConfirm={()=> navigate("/home")}
            minHeightContent={200}
            >

        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <img src={img32}/>
                <Text style={{color:"#101840", marginTop:20}}>Well Done!</Text>
                <Text style={{color:"#101840", marginTop:20}}></Text>
        </div>
                
        </Dialog>
    </Pane>

    )
}


export default LessonFinishedDialog;