import "../css/Page-Style.css";
import "./ErrorPage.css";
import { Link } from "react-router-dom";
import {Button} from "evergreen-ui"


// Images
import img17 from "../../images/illustrations/img17.svg";

import { useNavigate } from "react-router-dom";

const ErrorPage = ()=>{

const navigate = useNavigate();


    return(
        <div id="error_page" className="page_container_flex">
            <img id="error_image" src={img17}/>
            <p id="error_text">Oops : (</p>
            <p id="error_sub_text">Something unexpexted happened.</p>
            <Button id="error_btn" className="btn" appearance="primary" size="large" onClick={()=>navigate("/")}>
            Go Home
            </Button>
        </div>
    );
}

export default ErrorPage;