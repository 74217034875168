import { Link, useNavigate } from "react-router-dom";
import "./CharacterItem.css";
import { Dialog, Pane, Button, Text } from "evergreen-ui";
import { useState } from "react";
import {RiImage2Line} from "react-icons/ri";



// Item component for each row 
function CharacterItem({data}){


    
function Card(){


        

    return(
        <div className="level_item_container vocabulary" >
            
            {/* <img className="level_item_image" src={cat}/> */}

            {/* <div className="level_item_image_container">
                <div className="level_item_image" >
                <BsBookmarkFill color="#2137FA" fontSize={15}/>
                </div>
            </div> */}

            {/* <Text className="level_item_name">ZZ</Text> */}
            <Text fontSize={20} className="character_item_name">{data.hanzi}</Text>
   
        </div>
        )
    

}





return Card()

  

}



        
    
        
    



export default CharacterItem;