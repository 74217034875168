import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';


function WritingQuestion({answerList, answerListFunction, optionsArray}){

// The max amount of selected options at a time
  const INPUT_LIMIT = 8;

  let memoizedAnswerList = useMemo((i)=>{return answerList})



    return(
<>

<div id='answer-row'>
                          <div className="answer-area">
                          {answerList.map((item, index)=>{

                            if (item !== undefined && item.length > 0 ){
                              return(<div key={uuidv4()} className='item' data-character={item} data-index={index} onClick={(event)=>{

                                event.target.classList.remove("pop-in")
                                event.target.classList.add("pop-out")



                                setTimeout(()=>{
                                  let index = event.target.getAttribute('data-index');
                                  let dummy = answerList;
                                  delete dummy[index];
                                  answerListFunction([...dummy]);


                                  let clenedList = answerList.filter((i)=>{
                                    if (i !== undefined && i !== null){
                                      return i;
                                    }
                                  })
                            
                                  answerListFunction([...clenedList])


                                  console.log( typeof item ,answerList);
                                }, 400)
                            
                              }}
                              
                              >{item}</div>)
                            }
                            


                          })}
                            </div>
                        </div>

    <div id='option-row'>
                            { 
                            optionsArray.map((i)=>{
                                return (
                                
                                <div key={uuidv4()} className="option-container" data-character={i} onClick={(event)=>{

                                    let clenedList = answerList.filter((i)=>{
                                    if (i !== undefined && i !== null){
                                        return i;
                                    }
                                    })

                                    answerListFunction([...clenedList])
                        
                                    if (answerList.length < INPUT_LIMIT){
                                    answerListFunction([...answerList, event.target.getAttribute('data-character')])
                                    // console.log(answerList)
                                    }

                                }}>


                                    <div className='item' data-character={i} onClick={(event)=>{

                                        let clenedList = answerList.filter((i)=>{
                                        if (i !== undefined && i !== null){
                                            return i;
                                        }
                                        })

                                        answerListFunction([...clenedList])
                        
                                        if (answerList.length < INPUT_LIMIT){
                                        answerListFunction([...answerList, event.target.getAttribute('data-character')])
                                        // console.log(answerList)
                                        }

                                    }}>{i}</div>
                                            </div>)
                                            })
                            }
    </div>
</>
    );
}




export default WritingQuestion;