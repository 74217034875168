import { v4 as uuidv4 } from 'uuid';


function MultipleChoiceQuestion({answerList, answerListFunction, currentQuestionSet}){

return(
    <>
    
    <div id='answer-row'>
                          <div className="answer-area">
                          {answerList.map((item, index)=>{

                            if (item !== undefined && item.length >0 ){
                              return(<div key={uuidv4()} className='item' data-character={item} data-index={index} onClick={(event)=>{

                                event.target.classList.remove("pop-in")
                                event.target.classList.add("pop-out")



                                setTimeout(()=>{
                                  let index = event.target.getAttribute('data-index');
                                  let dummy = answerList;
                                  delete dummy[index];
                                  answerListFunction([...dummy]);


                                  let clenedList = answerList.filter((i)=>{
                                    if (i !== undefined && i !== null){
                                      return i;
                                    }
                                  })
                            
                                  answerListFunction([...clenedList])


                                  console.log( typeof item ,answerList);
                                },400)
                            
                              }}
                              
                              >{item}</div>)
                            }
                            


                          })}
                            </div>
                        </div>

    

    <div id='mcq-option-row'>
  { 
  currentQuestionSet?.options.map((i)=>{
      return (
      <div key={uuidv4()} className="mcq-option-container" data-character={i} onClick={(event)=>{

        let clenedList = answerList.filter((i)=>{
          if (i !== undefined && i !== null){
            return i;
          }
        })

        answerListFunction([...clenedList])

        if (answerList.length < 10){
          answerListFunction([...answerList, event.target.getAttribute('data-character')])
        }

      }}>


          <div className='item' data-character={i} onClick={(event)=>{

            let clenedList = answerList.filter((i)=>{
              if (i !== undefined && i !== null){
                return i;
              }
            })

            answerListFunction([...clenedList])

            if (answerList.length < 10){
              answerListFunction([...answerList, event.target.getAttribute('data-character')])
              // console.log(answerList)
            }

          }}>{i}</div>
                  </div>)
                })
  }
</div>

    </>
)

}

export default MultipleChoiceQuestion;