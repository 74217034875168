
import _ from "underscore";


// Data
import animals from "../../data/topics/animals.json"
import clothing from "../../data/topics/clothing.json"
import directions from "../../data/topics/directions.json"
import drinks from "../../data/topics/drinks.json"
import emotions from "../../data/topics/emotions.json"
import family from "../../data/topics/family.json"
import fruits from "../../data/topics/fruits.json"
import greetings from "../../data/topics/greetings.json"
import health from "../../data/topics/health.json"
import hobbies from "../../data/topics/hobbies.json"
import jobs from "../../data/topics/jobs.json"
import pets from "../../data/topics/pets.json"
import restaurants from "../../data/topics/restaurants.json"
import school from "../../data/topics/school.json"
import shopping from "../../data/topics/shopping.json"
import sports from "../../data/topics/sports.json"
import transport from "../../data/topics/transport.json"
import travel from "../../data/topics/travel.json"
import vegetables from "../../data/topics/vegetables.json"
import weather from "../../data/topics/weather.json"
import birthday from "../../data/topics/birthday.json"
import work from "../../data/topics/work.json"
import gym from "../../data/topics/gym.json"
import zoo from "../../data/topics/zoo.json"
import plants from "../../data/topics/plants.json"
import technology from "../../data/topics/technology.json"
import earth from "../../data/topics/earth.json"
import insects from "../../data/topics/insects.json"
import fastfood from "../../data/topics/fastfood.json"
import birds from "../../data/topics/birds.json"



// 
export function findLesson(file) {
    switch(file) {
        case 'animals':
            return animals;
        case 'clothing':
            return clothing;
        case 'directions':
            return directions;
        case 'drinks':
            return drinks;
        case 'emotions':
            return emotions;
        case 'family':
            return family;
        case 'fruits':
            return fruits;
        case 'greetings':
            return greetings;
        case 'health':
            return health;
        case 'hobbies':
            return hobbies;
        case 'jobs':
            return jobs;
        case 'pets':
            return pets;
        case 'restaurants':
            return restaurants;
        case 'school':
            return school;
        case 'shopping':
            return shopping;
        case 'sports':
            return sports;
        case 'transport':
            return transport;
        case 'travel':
            return travel;
        case 'vegetables':
            return vegetables;
        case 'weather':
            return weather;
        case 'birthday':
            return birthday;
        case 'work':
            return work;  
        case 'gym':
            return gym;
        case 'zoo':
            return zoo;    
        case 'plants':
            return plants;
        case 'technology':
            return technology;
        case 'earth':
            return earth;  
        case 'insects':
            return insects;
        case 'fastfoood':
            return fastfood;  
        case 'birds':
            return birds;          
        default:
            console.log('File not recognized');
    }
  }

// Add extra character to options
export function addRandomValuesToArray(currentQuestionSet, arr, OPTION_NUMBER) {

    console.log("Step 2", currentQuestionSet.answer)
  
    const arr2 = [...arr]
  
    const chineseCharacters = ["的", "一", "是", "在", "不", "了", "有", "和", "人", "这", "中", "大", "为", "上", "个", "国", "我", "以", "要", "他", "时", "来", "用", "们", "生", "到", "作", "地", "于", "出", "就", "分", "对", "成", "会", "可", "主", "发", "年", "动", "同", "工", "也", "能", "下", "过", "子", "说", "产", "种", "面", "而", "方", "后", "多", "定", "行", "学", "法", "所", "然", "家", "小", "义", "心", "理", "只", "如", "当", "如", "没", "起", "但", "从", "天", "个", "现", "实", "里", "己", "手", "数", "所", "成", "打", "算", "好", "要", "就", "美", "了", "会", "最", "说", "要", "们", "为", "面", "没", "事", "成", "可", "看", "对", "知", "道", "吗", "经", "提", "高", "爱", "护", "把", "全", "美", "其", "实", "到", "把", "就", "算", "研", "究", "比", "如", "上", "面", "所", "说", "由", "于", "目", "前", "国", "家", "改", "革", "开", "放", "最", "重", "要", "的", "的", "是", "弄", "清", "楚", "我", "们", "在", "新", "的", "历", "史", "条", "件", "下", "如", "何", "适", "应", "和", "发", "展", "我", "们", "一", "定", "要", "抓", "住", "机", "遇", "迎", "接", "挑", "战", "有", "信", "心", "有", "决", "心", "有", "办", "法", "创", "造", "好", "的", "工", "作", "和", "生", "活", "请", "大", "家", "共", "同", "努", "力", "愿", "大", "家", "取", "得", "更", "大", "的", "成", "绩", "祝", "大", "家", "新", "春", "快", "乐", "身", "体", "健", "康"]
  
    // Calculate the remaining length needed to reach 18
    const remainingLength = OPTION_NUMBER - arr2.length;
  
    // Add random characters to the array until the total length is 18
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random(1) * chineseCharacters.length);
      arr2.push(chineseCharacters[randomIndex]);
    }
  
    console.log("Step 3", currentQuestionSet.answer)
  
  
    return arr2;
  }

// Mixup all the question into a new order
export function RandomizeQuestions(questionSet, data, questionIndex, OPTION_NUMBER) {
    // console.log("Step 1",currentQuestionSet.answer)
  
    console.log(questionSet?.answer)
    let extendedSet = addRandomValuesToArray(data?.[questionIndex], questionSet?.answer, OPTION_NUMBER)
  
    let shuffled = _.shuffle(extendedSet)
    if (_.isEqual(shuffled, questionSet?.answer)) {
        let reshuffled = _.shuffle(shuffled)
        return reshuffled;
    }
  
    return shuffled;
  };


  