import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {useNavigate } from "react-router-dom";
import {Button, Text, Heading} from "evergreen-ui"

// Images
import logo from "../../images/illustrations/logo.svg";

// CSS
import "../css/Page-Style.css";
import "./SignInPage.css";

const SignUpPage = ()=>{

  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  // Prevent mobile
  // PreventMobile()


  //
  function handleGuestLogin(){
    navigate('/home');
  }
    

    return(
        <div id="login_page" className="page_container_flex">
            <div id="login_container">
              
              <div id="login_container_left">
                  <div className="signup_nav">
                  </div>

                  <div class="login_container_left_text">
                      <Heading fontSize={40} marginBottom={25}>Wennly</Heading>
                      <Text fontSize={17}>Build better grammer<br/>with characters you know</Text>
                  </div>

                  
              </div>

              <div id="login_container_right">

                <img className="signup_logo" width={100} src={logo} alt="wennly character"/>  

                <Heading size={800} marginBottom={40}>Sign In</Heading>

                <Button id="login_btn" className="btn" appearance="primary" size="large" width={350} onClick={()=>loginWithRedirect()}>Start Now</Button>

                <Button size="large" id="login_btn"  width={350}   onClick={() => handleGuestLogin()}>Contine as Guest</Button>

                <div className="already_have_container">
                  <span>
                  <Text size={300}>By using our service you aregee to our</Text>
                  <Text size={300} cursor="pointer" color="#3366ff" marginLeft={5} fontWeight={400} onClick={()=>navigate("/signup")}>Terms of Service</Text>
                  </span>
                  <Text size={300}>Wennly Copyright © {new Date().getFullYear()}</Text>
                </div>

              </div>

            </div>
        </div>
    );
}

export default SignUpPage;        