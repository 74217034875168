import React from 'react';
import { Pane, Dialog, Button, Text} from 'evergreen-ui';
import { useNavigate } from "react-router-dom";

import img25 from "../../images/illustrations/img25.svg";


// Dialog to return home
function RetunHomeDialog({shown, shownFunction}){

        // Page navigator object
        const navigate = useNavigate();

    return(
        <Pane>
        <Dialog
            title={"Return Home"}
            isShown={shown}
            preventBodyScrolling
            onCloseComplete={()=>{shownFunction(false)}}
            confirmLabel="Leave"
            cancelLabel="Cancel"
            hasClose={true}
            onConfirm={()=>{
                navigate("/home");
            }}
        >

        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <img src={img25}/>
            <Text style={{color:"#101840", marginTop:25}}>Are you sure you want to leave ?</Text>
        </div>
    
        </Dialog>
    </Pane>

    )
}


export default RetunHomeDialog;