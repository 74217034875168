import "../css/Page-Style.css";
import "./PreventMobilePage.css";



// Images
import img28 from "../../images/illustrations/img28.svg";

import { useNavigate } from "react-router-dom";

const PreventMobilePage = ()=>{

const navigate = useNavigate();


    return(
        <div id="error_page" className="page_container_flex">
            <img id="error_image" src={img28}/>
            <p id="error_text">Your screen is too small </p>
            <p id="error_sub_text">Please switch to a larger screen, desktop or tablet.</p>
        </div>
    );
}

export default PreventMobilePage;