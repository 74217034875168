import React from 'react';
import { Pane, Dialog, Button, Text} from 'evergreen-ui';
import { useNavigate } from "react-router-dom";

// Dialog to return home
function LessonAnswerDialog({data, shown, answer, correctAnswer, shownFunction, index, indexFunction, progress, progressFunction, progressRate, answerListFunction, finishedFunction}){

        function handleNext(){
            if (correctAnswer === true){

                // Is last question
                if (index === data.length - 1){
                    shownFunction(false) // close modal 
                    progressFunction(progress + progressRate) // increment progress
                    console.log("Game Over")
                    finishedFunction(true) // show game over
                }

                // Is not last question
                if (index !== data.length - 1){
                    setTimeout(()=>{
                        indexFunction( index + 1) // increment the index
                        progressFunction(progress + progressRate) // increment progress
                        answerListFunction([]) // clear the board and start again
                    } , 500)
    
                    shownFunction(false) // close modal 
                }

            }
        

            if (correctAnswer !== true){
                answerListFunction([]) // clear the board and start again
                shownFunction(false) // close modal
            }
        
        }

        function CorrectDialogContent(){
            return(
                <>
                <Text style={{color:"#101840", marginTop:25, fontSize:18}}>Correct answer!</Text>
                <Text style={{color:"#3366ff", marginTop:25, fontSize:25}}>{answer.toString().replaceAll(",", "")}</Text>
                </>
            )
        } 
        
        function WrongDialogContent(){
            return(
                <>
                <Text style={{color:"#101840", marginTop:25, fontSize:18}}>Oops. The correct answer is:</Text>
                <Text style={{color:"#3366ff", marginTop:25, fontSize:25}}>{answer.toString().replaceAll(",", "")}</Text>
                </>
            )
        }


    return(
        <Pane>
        <Dialog
        title={"Answer"}
        isShown={shown}
        preventBodyScrolling
        shouldCloseOnOverlayClick={true}
        onCloseComplete={()=>{
            shownFunction(false)
            handleNext()
        }}
        hasClose={true}
        hasCancel={false}
        confirmLabel={correctAnswer ? "Next" : "Try Again"}
        onConfirm={()=>{handleNext()}}
        >

        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
               {correctAnswer ? <CorrectDialogContent/> : <WrongDialogContent/> } 
        </div>

        </Dialog>
</Pane>
    )
}



export default LessonAnswerDialog;