
import React, { useEffect, useState, useMemo } from 'react';

// External
import { Button, IconButton, CrossIcon, Text, toaster, InfoSignIcon, VolumeOffIcon, RadioGroup} from 'evergreen-ui';
import ProgressBar from "@ramonak/react-progress-bar";
import { IoSettingsSharp } from "react-icons/io5";
import { useSearchParams } from 'react-router-dom';
import _ from 'underscore';

// JS
import {findLesson, RandomizeQuestions} from "./Functions"

// Components
import RetunHomeDialog from "../../components/Dialogs/ReturnHomeDialog";
import LessonInstrunctionDialog from '../../components/Dialogs/LessonInstructionDialog';
import SettingsDialog from '../../components/Dialogs/SettingsDialog';
import LessonFinishedDialog from '../../components/Dialogs/LessonFinishedDialog';
import LessonAnswerDialog from '../../components/Dialogs/LessonAnswerDialog';


// CSS
import "./ReadPage.css"


function ReadPage() {
    
  const [searchParams, setSearchParams] = useSearchParams();
  const lesson = searchParams.get('topic');

  // Memoize the results of findLesson
  const lessons = useMemo(() => findLesson(lesson), [lesson]);

  // Memoize the shuffled data
  const data = useMemo(() => _.shuffle(lessons), [lessons]);
  
  let data2 = data;

    const OPTION_NUMBER  = 22;
    const progressRate = 100 / data.length;

    // States
    const [isInstrunctionsShown, setIsInstrunctionsShown] = useState(false); // 
    const [isBackShown, setIsBackShown] = useState(false); // going back dialog state
    const [questionIndex, setQuestionIndex] = useState(0); // going back dialog state
    const [progress, setProgress] = useState(0); // progress bar value
    const [isFinishedShown, setIsFinishedShown] = useState(false); // modal
    const [isSettingShown, setIsSettingShown] = useState(false);  // modal
    const [isResultShown, setIsResultShown] = useState(false);  // modal
    const [answered, setAnswered] = useState(); //
    const [answerList, setAnswerList] = useState([]); // 



    const [options] = useState([
      { label: '一只兔子', value: '一只兔子' },
      { label: '一条小河', value: '一条小河' },
      { label: '一棵苹果树', value: '一棵苹果树' },
      { label: '一个洞穴', value: '一个洞穴' },
    ])



    const [value, setValue] = useState('restricted')

    // let randomizedOptions = useMemo(()=>RandomizeQuestions(data2?.[questionIndex], data, questionIndex, OPTION_NUMBER), [questionIndex]);


      function checkAnswer(){
        console.log(answerList, data[questionIndex].answer)

        let clenedList = answerList.filter((i)=>{
          if (i !== undefined && i !== null){
            return i;
          }
        })

        setAnswerList([...clenedList])


        let clenedList2 = answerList.filter((i)=>{
          if (i !== undefined && i !== null){
            return i;
          }
        })

        setAnswerList([...clenedList2])
        


        if (answerList.length !== data[questionIndex].answer.length){
          console.log("wrong !")
          setIsResultShown(true)
          setAnswered(false)
          return false
        }

          data[questionIndex].answer.forEach((item, index)=>{
            if (item.normalize() === answerList[index].normalize() ){
              let correctAnswer = data[questionIndex].answer.toString().normalize()
              let myAnswer = answerList.toString().normalize()
            
              if (correctAnswer === myAnswer){
                console.log("Passed !", correctAnswer === myAnswer)
                setIsResultShown(true)
                setAnswered(true)

                return true
              }

              if (correctAnswer !== myAnswer){
                console.log("wrong !")
                setIsResultShown(true)
                setAnswered(false)
                return false
              }
            } 
          }
        )  
      }


      

      return (
        <div id='game-page'>

     
            

        <div id='game-header' className="top_nav">

          <div id="top_nav_left">
              <IconButton className="nav_item" appearance="minimal" icon={CrossIcon} iconSize={20} onClick={()=>{
                        setIsBackShown(true)
                        toaster.closeAll();
                  }}>
              </IconButton>
          </div>

          <div id="top_nav_center">
              <ProgressBar id="game_progress" className="progress_bar_container" barContainerClassName="progress_bar_background" completed={progress} isLabelVisible={false} transitionDuration="0.2s" />
          </div>

          <div id="top_nav_right">
          <IconButton className="nav_item" appearance="minimal" icon={IoSettingsSharp} iconSize={20} onClick={()=>{
                          setIsSettingShown(true)
                          toaster.closeAll();
            }}>
          </IconButton>
          </div>

        </div>


       <div id='read-body'>

        <div id='read-left-panel'>
          <Text fontSize={30}>小猫咪的冒险</Text>
          <Text fontSize={17}>有一天，小猫咪小花决定去森林里探险。她穿过了高高的草丛，爬上了一棵大树，从树上望去，发现了一条美丽的小河。小花跳下树，跑到河边，看到水里有几条小鱼在游来游去。她很好奇，伸出爪子想要抓住一条鱼，但小鱼们太快了，轻松地逃开了。小花有点儿失望，但她继续在河边玩耍，最后她发现了一朵漂在水面的莲花。她轻轻地闻了闻，觉得好香啊！天色渐暗，小花知道该回家了。虽然没抓到鱼，但她开心地想：“今天真是一次有趣的冒险！”</Text>
        </div>

        <div id='read-right-panel'>

          <Text fontSize={17} marginBottom={40}>问题：小猫咪小花在森林里发现了什么？</Text>

          <div></div>

          <RadioGroup
          id='radio-group'
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          size={16}
          value={value}
          options={options}
          onChange={event => setValue(event.target.value)}
    />


          <Button disabled={answerList.length === 0} id='submit-btn' size='large' appearance='primary'onClick={()=>{checkAnswer()}}>Check</Button>

        </div>


        

        </div>
        </div>
      );
  }


export default ReadPage;