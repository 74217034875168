import React, {useState} from 'react';
import { Pane, Dialog, Text, Switch, } from 'evergreen-ui';

function SettingsDialog({shown, shownFunction}){


    const soundPref = window.localStorage.getItem("wennly-sound");
    const pinyinPref = window.localStorage.getItem("wennly-pinyin");

    // State
    const [soundState, setSoundState ] = useState(soundPref); // going back dialog state
    const [pinyinState, setPinyinState ] = useState(pinyinPref); // going back dialog state

    
    return(
        <Pane>
            <Dialog
                title={"Settings"}
                isShown={shown}
                preventBodyScrolling
                onCloseComplete={()=>{shownFunction(false)}}
                shouldCloseOnOverlayClick={true}
                hasClose={true}
                hasCancel={false}
                hasConfirm={false}
                hasFooter= {false}
                minHeightContent={200}
                confirmLabel="Close"
            >
                <span style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <Text style={{color:"#101840", marginTop:25}}>Sound</Text>
                            <Switch style={{marginTop:20}} disabled={soundState} onSelect={()=>{setSoundState(false)}}/>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <Text style={{color:"#101840", marginTop:25}}>Pinyin</Text>
                            <Switch style={{marginTop:20}} disabled={pinyinPref} onChange={()=>{setSoundState(false)}}/>
                        </div>
                </span>

            </Dialog>
    </Pane>
    )
}

export default SettingsDialog;