import { Text } from "evergreen-ui";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styling
import "./LevelItem.css";

// Item component for each row 
function LevelItem({data,  previewFunc}){

    function launchPreview(){

        previewFunc(
            {
                title: data.title,
                description: data.description,
                level: data.level,
                referance: data.reference,
                isShown: true
            }
        )

        window.localStorage.setItem("wennly-reference", data.reference)
    }




    
    function Card(){
    

        if (true){

        return(
            <div className="level_item_container"   onClick={()=>{ launchPreview() }}>
                
                <div className="level_item_image_container">
                    <div className="level_item_image" >
                    <FontAwesomeIcon fontSize={45} icon={`fa-solid ${data?.icon}`} />
                    </div>
                </div>

                <Text fontSize={17} className="level_item_name">{data?.title}</Text>
                {/* <Text color={"muted"} className="level_item_name">{data.description}</Text> */}

                {/* <div className="level_item_stats">
                    <Text fontSize={11} className="level_item_description">{data.level}</Text>
                    <Text fontSize={11} className="level_item_status new">New</Text>
                </div>    */}
            </div>
            )
        }

    }

    return Card()

}


export default LevelItem;