import "./CharacterList.css";
import CharacterRow from "../CharacterRow/CharacterRow";
import CharacterItem from "../CharacterItem/CharacterItem";
import { v4 as uuidv4 } from 'uuid';
import characterData from "../hsk-level-1.json"
import { Select, Option } from "evergreen-ui";

import { Table } from "evergreen-ui";

// List component for the level page
function CharacterList({data, previewFunc, titleFunc, descriptionFunc, levelFunc, modalFunc, referenceFunc, image }){
  return(

<div>

  <Select>
    <Option>sdfsd</Option>
    <Option>sdfsd</Option>
    <Option>sdfsd</Option>
  </Select>
  
<CharacterRow data={data} key={uuidv4()}/>

</div>

  );
}




export default CharacterList;