import React from 'react';
import { Pane, Dialog, Text} from 'evergreen-ui';

import img08 from "../../images/illustrations/img08.svg"

function LessonInstrunctionDialog({shown, shownFunction}){

    return(
        <Pane>
        <Dialog
        isShown={shown}
        onCloseComplete={()=>{shownFunction(false)}}
        preventBodyScrolling
        confirmLabel="Leave"
        cancelLabel="Cancel"
        hasFooter={false}
        hasClose={true}
        minHeightContent={400}
        >

        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <img src={img08}/>
            <Text style={{color:"#101840", marginTop:25}}>Click the character to write out the following sentances.</Text>
            <Text style={{color:"#101840", marginTop:25}}>Good Luck !</Text>
        </div>
    
        </Dialog>
    </Pane>
    )
}

export default LessonInstrunctionDialog;