import "./LevelList.css";
import LevelItem from "../LevelItem/LevelItem";
import LevelRow from "../LevelRow/LevelRow";
import { v4 as uuidv4 } from 'uuid';


// List component for the level page
function LevelList({data, previewFunc, titleFunc, descriptionFunc, levelFunc, modalFunc, referenceFunc, image }){
    return(
        <div className="level_list_container">

            {data.map((i)=>{ return(<LevelRow data={data} children={i.children} previewFunc={previewFunc} titleFunc={titleFunc} descriptionFunc={descriptionFunc} levelFunc={levelFunc} modalFunc={modalFunc} referenceFunc={referenceFunc} image={image} key={uuidv4()}/>)})}
 
        </div>
    );
}




export default LevelList;