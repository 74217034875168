
import React, { useEffect, useState, useMemo } from 'react';

// External
import { Button, IconButton, CrossIcon, Text, toaster, InfoSignIcon, VolumeOffIcon} from 'evergreen-ui';
import ProgressBar from "@ramonak/react-progress-bar";
import { IoSettingsSharp } from "react-icons/io5";
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import _ from 'underscore';

// JS
import {findLesson, RandomizeQuestions} from "./Functions"

// Components
import RetunHomeDialog from "../../components/Dialogs/ReturnHomeDialog";
import LessonInstrunctionDialog from '../../components/Dialogs/LessonInstructionDialog';
import SettingsDialog from '../../components/Dialogs/SettingsDialog';
import LessonFinishedDialog from '../../components/Dialogs/LessonFinishedDialog';
import LessonAnswerDialog from '../../components/Dialogs/LessonAnswerDialog';
import WritingQuestion from './components/WritingQuestion';
import MultipleChoiceQuestion from './components/MultipleChoiceQuestion';

// CSS
import "./LessonPage.css"


function LessonPage() {
    
  const [searchParams, setSearchParams] = useSearchParams();
  const lesson = searchParams.get('topic');

  // Memoize the results of findLesson
  const lessons = useMemo(() => findLesson(lesson), [lesson]);

  // Memoize the shuffled data
  const data = useMemo(() => _.shuffle(lessons), [lessons]);
  
  let data2 = data;

    const OPTION_NUMBER  = 12;
    const progressRate = 100 / data.length;

    // States
    const [isInstrunctionsShown, setIsInstrunctionsShown] = useState(false); // 
    const [isBackShown, setIsBackShown] = useState(false); // going back dialog state
    const [questionIndex, setQuestionIndex] = useState(0); // going back dialog state
    const [progress, setProgress] = useState(0); // progress bar value
    const [isFinishedShown, setIsFinishedShown] = useState(false); // modal
    const [isSettingShown, setIsSettingShown] = useState(false);  // modal
    const [isResultShown, setIsResultShown] = useState(false);  // modal
    const [answered, setAnswered] = useState(); //
    const [answerList, setAnswerList] = useState([]); // 


    let currentQuestionSet = data?.[questionIndex] ? data?.[questionIndex] : ""

    let randomizedOptions = useMemo(()=>RandomizeQuestions(data2?.[questionIndex], data, questionIndex, OPTION_NUMBER), [questionIndex]);


      function checkAnswer(){
        console.log(answerList, data[questionIndex].answer)

        let clenedList = answerList.filter((i)=>{
          if (i !== undefined && i !== null){
            return i;
          }
        })

        setAnswerList([...clenedList])


        let clenedList2 = answerList.filter((i)=>{
          if (i !== undefined && i !== null){
            return i;
          }
        })

        setAnswerList([...clenedList2])
        


        if (answerList.length !== data[questionIndex].answer.length){
          console.log("wrong !")
          setIsResultShown(true)
          setAnswered(false)
          return false
        }

          data[questionIndex].answer.forEach((item, index)=>{
            if (item.normalize() === answerList[index].normalize() ){
              let correctAnswer = data[questionIndex].answer.toString().normalize()
              let myAnswer = answerList.toString().normalize()
            
              if (correctAnswer === myAnswer){
                console.log("Passed !", correctAnswer === myAnswer)
                setIsResultShown(true)
                setAnswered(true)

                return true
              }

              if (correctAnswer !== myAnswer){
                console.log("wrong !")
                setIsResultShown(true)
                setAnswered(false)
                return false
              }
            } 
          }
        )  
      }


      return (
        <div id='game-page'>

        {/*--  Instruction modal  --*/}
        <LessonInstrunctionDialog shown={isInstrunctionsShown} shownFunction={setIsInstrunctionsShown}/>

        {/*--  The return to the home page modal  --*/}
        <RetunHomeDialog shown={isBackShown} shownFunction={setIsBackShown}/>

        {/*--  The settings game modal  --*/}
        <SettingsDialog shown={isSettingShown} shownFunction={setIsSettingShown}/>

        {/*--  The finished game modal  --*/}
        <LessonFinishedDialog shown={isFinishedShown} shownFunction={setIsFinishedShown}/>

        {/*--  The results game modal  --*/}
        <LessonAnswerDialog data={data} shown={isResultShown} shownFunction={setIsResultShown} correctAnswer={answered} answer={currentQuestionSet?.answer} index={questionIndex} indexFunction={setQuestionIndex} progress={progress} progressFunction={setProgress} progressRate={progressRate} answerListFunction={setAnswerList} finishedFunction={setIsFinishedShown}/>
            

        <div id='game-header' className="top_nav">

          <div id="top_nav_left">
              <IconButton className="nav_item" appearance="minimal" icon={CrossIcon} iconSize={20} onClick={()=>{
                        setIsBackShown(true)
                        toaster.closeAll();
                  }}>
              </IconButton>
          </div>

          <div id="top_nav_center">
              <ProgressBar id="game_progress" className="progress_bar_container" barContainerClassName="progress_bar_background" completed={progress} isLabelVisible={false} transitionDuration="0.2s" />
          </div>

          <div id="top_nav_right">
          <IconButton className="nav_item" appearance="minimal" icon={IoSettingsSharp} iconSize={20} onClick={()=>{
                          setIsSettingShown(true)
                          toaster.closeAll();
            }}>
          </IconButton>
          </div>

        </div>


       <div id='game-body'>

          <Text id="question" fontSize={20}>{currentQuestionSet?.translation}</Text>      

          {currentQuestionSet?.type === "wrt" ?  <WritingQuestion answerList={answerList} answerListFunction={setAnswerList} optionsArray={randomizedOptions}/> : ""}

          {currentQuestionSet?.type === "mcq" ? <MultipleChoiceQuestion answerList={answerList} answerListFunction={setAnswerList} currentQuestionSet={currentQuestionSet}/>  : ""}

          <Button disabled={answerList.length === 0} id='submit-btn' size='large' appearance='primary'onClick={()=>{checkAnswer()}}>Check</Button>

        </div>
        </div>
      );
  }


export default LessonPage;