"use strict";

// React
import { useEffect, useState } from 'react';

// External
import {Menu, Pane,  Position,  
  Avatar, Dialog, Popover, Text, Button, IconButton, PeopleIcon, CircleArrowRightIcon,  TrashIcon, CogIcon, LogOutIcon} from 'evergreen-ui'
import { useNavigate, useLocation } from "react-router-dom";
import { ProSidebar, MenuItem, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';
import {Menu as MenuGroup} from "react-pro-sidebar"
import { BsGridFill } from "react-icons/bs";
import { useAuth0 } from "@auth0/auth0-react";

// Icons
import { IoSettingsSharp } from "react-icons/io5";
import { IoBookmark } from "react-icons/io5";

// Components
import LevelList from './components/LevelList/LevelList';
import CharacterList from './components/CharacterList/CharacterList';
import Dashboard from './components/Dashboard/Dashboard';
import SettingsDialog from '../../components/Dialogs/SettingsDialog';
import WelcomeDialog from '../../components/Dialogs/WelcomeDialog';

// Page Data
import topics from "../../data/topics_display.json";
import vocabulary from "./components/hsk-level-1.json"

// Images
import logo from "../../images/illustrations/logo.svg";
import img27 from "../../images/illustrations/img27.svg";

// Styles
import "../css/Page-Style.css";
import "./HomePage.css";
import "../../../node_modules/react-pro-sidebar/dist/css/styles.css";


const HomePage = () => {


  //
  const { user, isAuthenticated, isLoading, logout} = useAuth0();

    // Pass data between pages
    const navigate = useNavigate();

    // States
    const [loading, setLoading] = useState(true);
    const [datasets, setDatasets] = useState(null);
    const [isWelcomeShown, setIsWelcomeShown] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [previewData, setPreviewData] = useState({})
    const [isSettingShown, setIsSettingShown] = useState(false);  // modal
    const [image, setImage] = useState(img27); //
    const [classIndex, setClassIndex] = useState(0); // data index 
    const [componentName, setComponentName] = useState("lesson"); 


    // Get initial
    useEffect(() => {
      const fetchData = async () => {
          setDatasets(topics);
          setLoading(false);
        }

      fetchData();
    }, []); // Empty dependency array means this effect runs only once after the component mounts


    // First time visitors
    useEffect(() => {
      const firstTime = window.localStorage.getItem("wennly-first-time");

      if (firstTime === null || undefined){
        // first time
        setIsFirstTime(true);
        // display welcome
        setIsWelcomeShown(true);

        window.localStorage.setItem("wennly-first-time", false);
        window.localStorage.setItem("wennly-sound", true);
        window.localStorage.setItem("wennly-pinyin", false);

      }

    },
    [isWelcomeShown]); 

    const PreloadImage = ({ src }) => {
      useEffect(() => {
        const img = new Image();
        img.src = src;
      }, [src]);
    
      return null; // You can return null because this component doesn't render anything
    };
    



// Menu button hanndler
function menuButtonHandler(name){
  const selectedComponent = name;
  console.log(selectedComponent)
  setComponentName(selectedComponent)
}


let spinner = ""


  return (
  <div id='home' >

<PreloadImage src="../../images/illustrations/logo.svg" />
<PreloadImage src="../../images/illustrations/img27.svg" />
<PreloadImage src="../../images/illustrations/img08.svg" />

    {/*-  The course preview modal  -*/}
    <Pane>
      <Dialog 
      isShown={previewData.isShown}
      title={previewData.title}
      onCloseComplete={()=>{setPreviewData({isShown:false})}}
      preventBodyScrolling
      confirmLabel="Start"
      cancelLabel='Cancel'
      hasClose={false}
      onConfirm={() => {

      navigate({pathname:"/lesson", search:`?topic=${previewData.referance}` });
        
    }}
      >

          <div style={{display:"flex", flexDirection:"column"}}>
            <span style={{marginTop:10}}>  <Text color={"muted"}>Description:</Text> <Text color="#3366ff">{previewData.description}</Text> </span>
            <span style={{marginTop:10}}>  <Text color={"muted"}>Key Words:</Text> <Text color="#3366ff">15</Text> </span>
          </div>
        
      </Dialog>
    </Pane>
    {/*--  End  --*/}


    {/*-  The settings modal  -*/}
    <SettingsDialog show={isSettingShown} shownFunction={setIsSettingShown}/>

    {/*-  The welcome modal  -*/}
    <WelcomeDialog shown={isWelcomeShown} shownFunction={setIsWelcomeShown}/>
  
  {/*  */}
  <ProSidebar collapsed={false} id='home_nav_container'>

    <SidebarHeader>
      <div id='home_logo_container'>
        <img src={logo} width={45} alt='logo'/>
      </div>
    </SidebarHeader>

    <MenuGroup iconShape="round">
        {/* <MenuItem  icon={<IoGameController size={20}/>} onClick={()=>{menuButtonHandler("dashboard")}}>Characters</MenuItem> */}
        {/* <MenuItem  icon={<IoGameController size={20}/>} onClick={()=>{menuButtonHandler("vocabulary")}}>Characters</MenuItem> */}
        <MenuItem  icon={<BsGridFill size={20}/>} onClick={()=>{menuButtonHandler("lesson")}}>Characters</MenuItem>
        {/* <MenuItem  icon={<IoBookmark size={20}/>} onClick={()=>{menuButtonHandler("lesson")}}>Characters</MenuItem> */}

    </MenuGroup>

<SidebarFooter>
</SidebarFooter>

  </ProSidebar>



    <div className='home_body_container'>
      <div className='top_nav'>
        <div id="top_nav_left">
            {/* <Text fontSize={20} color="#3366ff" >Welcome Back, Guest!</Text> */}
        </div>

        <div id="top_nav_right">

          <div id='avatar-wrapper'>
                    <div>
                      <p id="footer_username">{isAuthenticated ? user.name : "Guest"}</p> 
                    </div>
                    <Popover
  position={Position.BOTTOM_LEFT}
  content={
    <Menu>
      <Menu.Group>
        <Menu.Item icon={IoSettingsSharp} onClick={()=>{
          setIsSettingShown(true)
          console.log("open settings")
          }}>Settings</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item icon={LogOutIcon} intent="info" onClick={() => logout({ logoutParams: { returnTo: window.location.origin + "/" } })}>
          Sign Out
        </Menu.Item>
      </Menu.Group>
    </Menu>
  }
>
<Avatar src={isAuthenticated ? user.picture : ""} name={isAuthenticated ? user.name : "Guest"} color="blue" size={35} style={{marginRight:10, marginLeft:10, cursor:"pointer"}}/>
</Popover>
          </div>             
        </div>
      </div>
       
      {!loading & componentName === "dashboard" ? <Dashboard />  : spinner } 
      {!loading & componentName === "lesson" ? <LevelList data={datasets[classIndex]} image={image} previewFunc={setPreviewData} />  : spinner } 
      {!loading & componentName === "vocabulary" ? <CharacterList data={vocabulary} previewFunc={setPreviewData} image={image}  />  : spinner } 

    </div>
    
  </div>
  )

};

export default HomePage;