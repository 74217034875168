import "./CharacterRow.css"
import CharacterItem from "../CharacterItem/CharacterItem";
import { v4 as uuidv4 } from 'uuid';

import characterData from "../hsk-level-1.json"


// Row component for the level list
function CharacterRow(){

    return(
        <div className="level_row_container wrap">

        {characterData.map((item)=>{return(<CharacterItem data={item} key={uuidv4()} />)})}
            
        </div>
    );
}



export default CharacterRow;