import "./LevelRow.css"
import LevelItem from "../LevelItem/LevelItem";
import { v4 as uuidv4 } from 'uuid';

// Row component for the level list
function LevelRow({children, previewFunc, titleFunc, descriptionFunc, levelFunc, modalFunc, referenceFunc, image }){

    return(
        <div className="level_row_container">

        {children.map((item)=>{return(<LevelItem data={item} previewFunc={previewFunc} titleFunc={titleFunc} descriptionFunc={descriptionFunc} levelFunc={levelFunc} modalFunc={modalFunc} referenceFunc={referenceFunc} image={image} key={uuidv4()} />)})}
            
        </div>
    );
}



export default LevelRow;